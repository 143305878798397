import React from "react";
import banner from "../images/homeMain.jpg";
import boat from "../images/yacht1.jpg";
import commercial from "../images/commercial.jpg";
import Section from "../components/Section";
import individual from "../images/personal2.jpg";
import businessPic from "../images/business.jpg";
import largeBusiness from "../images/largeBusiness.jpg";
import QuoteSection from "../components/QuoteSection";
import { Helmet } from "react-helmet";
import GrowFade from "../components/GrowFade";
import RotateText from "../components/RotateText";
import { useLocation } from "react-router-dom";
import { useState, useEffect } from "react";

function Home() {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const success = queryParams.get("success");
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [isVisible, setIsVisible] = useState(true);

  useEffect(() => {
    if (success) {
      setShowSuccessMessage(true);
      const timeout = setTimeout(() => {
        setIsVisible(false);
      }, 3500);
      const timeout2 = setTimeout(() => {
        setShowSuccessMessage(false);
      }, 5000);
      return () => clearTimeout(timeout, timeout2);
    }
  }, [success]);

  const marine = [
    { pic: boat, title: "PLEASURE YACHT", link: "/pleasure-yacht" },
    {
      pic: commercial,
      title: "COMMERCIAL MARINE",
      link: "/commercial-marine",
    },
  ];

  const personal = [
    {
      pic: individual,
      title: "PERSONAL, LIFE, HOME, ETC.",
      link: "/personal-insurance",
    },
  ];

  const business = [
    { pic: businessPic, title: "SMALL BUSINESS", link: "/small-business" },
    {
      pic: largeBusiness,
      title: "LARGE BUSINESS",
      link: "/large-business",
    },
  ];
  return (
    <>
      <Helmet>
        <title>Allied Financial Network | Home</title>
      </Helmet>
      {showSuccessMessage && (
        <div
          className={
            isVisible
              ? "success-message position-fixed"
              : "success-message position-fixed fadeOutMessage"
          }
        >
          <p className="mb-0 fs-4 shadow-lg position-absolute top-0 start-50 translate-middle-x border border-2 border-info py-2 px-3 bg-light w-100 text-center">
            Thank you for your message! We will contact you shortly.
          </p>
        </div>
      )}
      <div className="position-relative bg-dark overflow-hidden">
        <img
          src={banner}
          className="w-100 fullScreen opacity-img"
          alt="banner"
          style={{ height: "75vh" }}
        />
        <div className="position-absolute top-50 start-50 translate-middle w-100 text-center">
          <GrowFade />
        </div>
        <div className="position-absolute top-50 start-50 translate-middle w-100 text-center">
          <RotateText />
        </div>
      </div>
      <QuoteSection />
      <div className="py-5 bg-darkBlue text-white">
        <Section pics={marine} title="MARINE INSURANCE" />
      </div>
      <div className="py-5">
        <Section pics={personal} title="PERSONAL INSURANCE" />
      </div>
      <div className="py-5 bg-darkGreen text-white">
        <Section pics={business} title="BUSINESS INSURANCE" />
      </div>
    </>
  );
}

export default Home;
