import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

function Carousel({ items }) {
  const settings = {
    dots: false,
    arrows: false,
    autoplay: true,
    pauseOnHover: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    adaptiveHeight: true,
  };

  console.log(items);

  return (
    <Slider {...settings}>
      {items.map((line) => {
        return (
          <div
            key={line.id}
            className="px-1 lh-base display-2 fw-bold fst-italic text-white shadows"
          >
            {line.line}
          </div>
        );
      })}
    </Slider>
  );
}

export default Carousel;
