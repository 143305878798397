import InfoPages from "../components/InfoPages";
import QuoteSection from "../components/QuoteSection";
import aboutPic from "../images/steveBonner.jpg";
import { Helmet } from "react-helmet";

function About() {
  return (
    <>
      <Helmet>
        <title>Allied Financial Network | About</title>
      </Helmet>
      <InfoPages />
      <div className="container-lg">
        <div className="row pt-5 justify-content-center">
          <div className="col-11 col-md-10 col-lg-9">
            <img src={aboutPic} alt="about" className="w-25 float-start me-2" />
            <p>
              ALLIED FINANCIAL NETWORK was founded in 1988 with the desire to
              provide personalized service to all of our clients. Where we have
              grown and developed 1000s of clients, Steve Bonner still manages
              to deal personally with all of them, using his 32 years and
              counting of expert insurance knowledge to provide the very best in
              insurance solutions.
            </p>
            <p>
              Most of our clients are business owners who improve on all facets
              of their business insurances and improve benefits while reducing
              expenses. This is done with care and concern for our clients,
              making sure they are optimally protected and their goals are
              exceeded. Once our clients experience this level of service and
              expertise, they seek to achieve the same success with all of their
              personal lines as well. We will help anyone and everyone,
              regardless of how large or small their coverages might be. Our
              goal is simple... make our clients happy by going the extra mile,
              reducing costs and improving coverages.
            </p>
            <p>
              Steven Bonner, founder, is an avid sailor with 2 circumnavigations
              on his resume and has become a world renowned marine insurance
              specialist insuring world cruisers in virtually any location on
              earth to commercial yachts of all sizes and values. When it comes
              to sailing knowledge, speaking the marine language, knowing what
              sailors are going through to recommendations for a haul out or how
              to get a part, Mr. Bonner is a wealth of information for his
              clients as well.
            </p>
            <p>
              After taking off on his 2nd circumnavigation in 2017 with his
              family from Ft. Lauderdale, FL on his yacht Chasing Waterfalls, he
              eventually landed in Honolulu, Hawaii and opened up his second
              insurance agency in Waikiki in 2021.
            </p>
            <p>
              Clients love the personal touch and Mr. Bonner takes care of every
              single client himself. We have not done any marketing in 25 years
              and are 100% word of mouth from happy clients.
            </p>
            <hr className="mt-5 mb-0" />
          </div>
        </div>
      </div>
      <QuoteSection />
    </>
  );
}

export default About;
