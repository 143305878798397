import React from "react";
import { Link } from "react-router-dom";
import logo from "../images/logo.jpg";

function Footer() {
  return (
    <div className="footer">
      <div className="container-lg py-5">
        <div className="row justify-content-center">
          <div className="col-11 col-md-4 order-1 order-md-2 d-flex align-items-end justify-content-center">
            <div>
              <div className="text-center">
                <Link to="/">
                  <img
                    src={logo}
                    alt="logo"
                    className="mb-4 px-2 py-1 bg-white shadow rounded"
                    style={{ height: "5rem", border: "1px solid black" }}
                  />
                </Link>
                <p>
                  1831 Ala Moana Blvd, Suite 205
                  <br />
                  Honolulu, HI 96815
                </p>
                <hr className="w-100" />
                <p className="mb-md-0">
                  3141 Stevens Creek Blvd, Suite 40003
                  <br />
                  San Jose, CA 95117
                </p>
              </div>
            </div>
          </div>
          <div className="col-11 col-md-4 order-3 d-flex align-items-end justify-content-center">
            <div>
              <p className="fw-bold mb-0">Marine Insurance</p>
              <ul>
                <li>
                  <Link
                    className="text-decoration-none text-white"
                    to="/pleasure-yacht"
                  >
                    Pleasure Yacht
                  </Link>
                </li>
                <li>
                  <Link
                    className="text-decoration-none text-white"
                    to="/commercial-marine"
                  >
                    Commercial Marine
                  </Link>
                </li>
              </ul>
              <p className="fw-bold mb-0">Personal Insurance</p>
              <ul>
                <li>
                  <Link
                    className="text-decoration-none text-white"
                    to="/personal-insurance"
                  >
                    Personal, Life, Home, etc.
                  </Link>
                </li>
              </ul>
              <p className="fw-bold mb-0">Business Insurance</p>
              <ul className="mb-0">
                <li>
                  <Link
                    className="text-decoration-none text-white"
                    to="/small-business"
                  >
                    Small Business
                  </Link>
                </li>
                <li>
                  <Link
                    className="text-decoration-none text-white"
                    to="/large-business"
                  >
                    Large Business
                  </Link>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-11 col-md-4 order-2 order-md-1 d-flex align-items-end justify-content-center">
            <div>
              <p>
                <a
                  className="text-decoration-none text-white"
                  href="tel:8088243626"
                >
                  <span className="fw-bold">Phone:</span> 808-824-3626
                </a>
              </p>
              <p>
                <a
                  className="text-decoration-none text-white"
                  href="mailto:steve@alliedfinancialnetwork.com"
                >
                  <span className="fw-bold">Email:</span>{" "}
                  steve@alliedfinancialnetwork.com
                </a>
              </p>
              <p className="fw-bold">Business Hours:</p>
              <p>Mon-Fri 9am-5pm</p>
              <p className="mb-md-0">Sat-Sun Closed</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;
