import { useRef, useState } from "react";
import Button from "react-bootstrap/Button";
import { Helmet } from "react-helmet";
import { useLocation, useNavigate } from "react-router-dom";
import InfoPages from "../components/InfoPages";
import emailjs from "@emailjs/browser";

function Contact() {
  const location = useLocation();
  const navigate = useNavigate();
  const fromQuotePage = location.state?.insurance;
  const form = useRef();

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [type, setType] = useState(fromQuotePage);
  const [about, setAbout] = useState("");
  const [errorName, setErrorName] = useState("formInput");
  const [errorEmail, setErrorEmail] = useState("formInput");
  const [errorPhone, setErrorPhone] = useState("formInput");
  const [errorType, setErrorType] = useState("formInput");
  const [errorAbout, setErrorAbout] = useState("formInput");
  const [waitMessage, setWaitMessage] = useState("");

  const onSubmit = (e) => {
    e.preventDefault();
    if (!name) {
      setErrorName("errorFormInput");
    } else setErrorName("formInput");
    if (!email) {
      setErrorEmail("errorFormInput");
    } else setErrorEmail("formInput");
    if (!phone) {
      setErrorPhone("errorFormInput");
    } else setErrorPhone("formInput");
    if (!type) {
      setErrorType("errorFormInput");
    } else setErrorType("formInput");
    if (!about) {
      setErrorAbout("errorFormInput");
    } else setErrorAbout("formInput");

    if (name && email && phone && type && about) {
      setWaitMessage("Please wait...");

      emailjs
        .sendForm(
          "service_70dkx5g",
          "template_17b0szl",
          form.current,
          "lIvuQcS29W-1LzLJc"
        )
        .then(
          function (response) {
            setName("");
            setEmail("");
            setPhone("");
            setType("");
            setAbout("");
            setWaitMessage("Your message has been sent.");
            console.log("SUCCESS!", response.status, response.text);
            navigate("/?success=true");
          },
          function (err) {
            console.log("FAILED...", err);
            setWaitMessage(
              "There was an error. Please reload the page and try again."
            );
          }
        );
    } else {
      setWaitMessage("Required field(s) missing. Please check form.");
    }
  };
  return (
    <>
      <Helmet>
        <title>Allied Financial Network | Contact</title>
      </Helmet>
      <InfoPages />
      <div className="container-lg">
        <div className="row justify-content-center py-5">
          <div className="col-12 col-md-6 col-lg-6 text-center">
            <h3 className="quoteFormHeader text-white">
              <strong>GET A QUOTE</strong>
            </h3>
            <div className="quoteForm">
              <form ref={form} onSubmit={onSubmit}>
                <div className="container-fluid">
                  <div className="row justify-content-center pt-4">
                    <div className="col-6">
                      <input
                        className={errorName}
                        value={name}
                        name="name"
                        type="text"
                        placeholder="Full Name"
                        onChange={(e) => {
                          setName(e.target.value);
                          setErrorName("formInput");
                        }}
                      />
                    </div>
                    <div className="col-6">
                      <input
                        className={errorEmail}
                        value={email}
                        name="email"
                        type="email"
                        placeholder="Email Address"
                        onChange={(e) => {
                          setEmail(e.target.value);
                          setErrorEmail("formInput");
                        }}
                      />
                    </div>
                  </div>
                  <div className="row justify-content-center">
                    <div className="col-6">
                      <input
                        className={errorPhone}
                        value={phone}
                        name="phone"
                        type="text"
                        placeholder="Phone Number"
                        onChange={(e) => {
                          setPhone(e.target.value);
                          setErrorPhone("formInput");
                        }}
                      />
                    </div>
                    <div className="col-6">
                      <select
                        className={errorType}
                        value={type}
                        name="type"
                        onChange={(e) => {
                          setType(e.target.value);
                          setErrorType("formInput");
                        }}
                      >
                        <option value="">Insurance Type</option>
                        <option value="" disabled>
                          MARINE INSURANCE
                        </option>
                        <option value="Marine Insurance - Pleasure Yacht">
                          Pleasure Yacht
                        </option>
                        <option value="Marine Insurance - Commercial">
                          Commercial Marine
                        </option>
                        <option value="" disabled>
                          PERSONAL INSURANCE
                        </option>
                        <option value="Personal Insurance">Personal</option>
                        <option value="Life Insurance">Life</option>
                        <option value="Home Insurance">Home</option>
                        <option value="" disabled>
                          BUSINESS INSURANCE
                        </option>
                        <option value="Small Business Insurance">
                          Small Business
                        </option>
                        <option value="Large Business Insurance">
                          Large Business
                        </option>
                      </select>
                    </div>
                  </div>
                  <div className="row justify-content-center">
                    <div className="col-12">
                      <textarea
                        rows="8"
                        className={errorAbout}
                        value={about}
                        name="about"
                        placeholder="Describe your insurance needs"
                        onChange={(e) => {
                          setAbout(e.target.value);
                          setErrorAbout("formInput");
                        }}
                      />
                    </div>
                  </div>
                  {waitMessage ? (
                    <p className="waitMessage">
                      <em>{waitMessage}</em>
                    </p>
                  ) : (
                    ""
                  )}
                  <Button
                    className="quoteFormButton"
                    variant="dark"
                    type="submit"
                  >
                    Send
                  </Button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Contact;
