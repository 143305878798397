import { Link } from "react-router-dom";
import Button from "react-bootstrap/Button";

function QuoteSection() {
  return (
    <div className="container-lg">
      <div className="row py-5 justify-content-center">
        <div className="text-center">
          <h1 className="fw-bold">OPTIMIZE YOUR WORLD</h1>
          <h2 className="pt-3">
            Contact us to customize the best insurance for you.
          </h2>
          <Button
            as={Link}
            to="/contact"
            size="lg"
            variant="success"
            className="mt-4 shadow"
          >
            GET A QUOTE
          </Button>
        </div>
      </div>
    </div>
  );
}

export default QuoteSection;
