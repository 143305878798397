import React, { useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import { BsTelephone } from "react-icons/bs";
import Carousel from "../components/Carousel";

function RotateText() {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setIsVisible(true);
    }, 3500);

    return () => {
      clearTimeout(timeoutId);
    };
  }, []);

  const lines = [
    { id: 1, line: "Optimize Your World" },
    { id: 2, line: "Specializing in Worldwide Marine Insurance" },
    { id: 3, line: "In Business since 1990" },
    { id: 4, line: "For All Your Insurance Needs" },
    { id: 5, line: "Insuring All Companies Big and Small" },
    { id: 6, line: "Insuring All Personal Lines" },
  ];

  lines.sort(() => {
    return 0.5 - Math.random();
  });

  return (
    <div className={isVisible ? "fadeIn" : "fadeOut"}>
      <Carousel items={lines} />

      <Button
        href="tel:8088243626"
        size="lg"
        variant="light"
        className="mt-4 shadow"
      >
        <BsTelephone className="mb-1" /> 808-824-3626
      </Button>
    </div>
  );
}

export default RotateText;
