import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import Container from "react-bootstrap/Container";
import NavDropdown from "react-bootstrap/NavDropdown";
import logo from "../images/logo.jpg";
import { BiPhoneCall } from "react-icons/bi";
import Button from "react-bootstrap/Button";

function Header() {
  const ref = useRef(null);
  const [expanded, setExpanded] = useState(false);

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        setExpanded(false);
      }
    };

    document.addEventListener("click", handleOutsideClick);
    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, []);

  return (
    <>
      <Navbar
        className="boxShadow py-0"
        expanded={expanded}
        sticky="top"
        bg="light"
        expand="lg"
        ref={ref}
      >
        <Container fluid className="navContainer d-flex">
          <Navbar.Brand onClick={() => setExpanded(false)} as={Link} to="/">
            <img src={logo} className="w-auto logo" alt="logo" />
          </Navbar.Brand>
          <div>
            <Button
              variant="outline-secondary"
              href="tel:8088243626"
              className="me-3 headerPhoneButton"
            >
              <BiPhoneCall className="headerPhone" />
            </Button>
            <Navbar.Toggle
              onClick={() => setExpanded(!expanded)}
              aria-controls="basic-navbar-nav"
            />
          </div>
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav>
              <Nav.Link
                onClick={() => setExpanded(false)}
                className="ms-4"
                as={Link}
                to="/about"
              >
                ABOUT
              </Nav.Link>
              <NavDropdown
                className="ms-4"
                title="MARINE INSURANCE"
                id="basic-nav-dropdown"
              >
                <NavDropdown.Item
                  onClick={() => setExpanded(false)}
                  as={Link}
                  to="/pleasure-yacht"
                >
                  PLEASURE YACHT
                </NavDropdown.Item>
                <NavDropdown.Item
                  onClick={() => setExpanded(false)}
                  as={Link}
                  to="/commercial-marine"
                >
                  COMMERCIAL MARINE
                </NavDropdown.Item>
              </NavDropdown>
              <Nav.Link
                onClick={() => setExpanded(false)}
                className="ms-4"
                as={Link}
                to="/personal-insurance"
              >
                PERSONAL INSURANCE
              </Nav.Link>
              <NavDropdown
                className="ms-4"
                title="BUSINESS INSURANCE"
                id="basic-nav-dropdown"
              >
                <NavDropdown.Item
                  onClick={() => setExpanded(false)}
                  as={Link}
                  to="/small-business"
                >
                  SMALL BUSINESS INSURANCE
                </NavDropdown.Item>
                <NavDropdown.Item
                  onClick={() => setExpanded(false)}
                  as={Link}
                  to="/large-business"
                >
                  LARGE BUSINESS INSURANCE
                </NavDropdown.Item>
              </NavDropdown>
              <Nav.Link
                onClick={() => setExpanded(false)}
                className="ms-4"
                as={Link}
                to="/contact"
              >
                CONTACT
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );
}

export default Header;
