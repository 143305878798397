import { BrowserRouter, Routes, Route } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import ScrollToTop from "./components/ScrollToTop";
import Home from "./pages/Home";
import About from "./pages/About";
import Header from "./components/Header";
import InsurancePage from "./components/InsurancePage";
import Contact from "./pages/Contact";
import Footer from "./components/Footer";
import boat from "./images/boatBanner.jpg";
import sailboat from "./images/sailboat2.jpg";
import boatPic1 from "./images/boatPic1.jpg";
import boatPic2 from "./images/boatPic2.jpg";
import boatPic3 from "./images/boatPic3.jpg";
import freight from "./images/freight.jpg";
import yacht from "./images/yachtBoat.jpg";
import personal from "./images/personalBanner.jpg";
import personal1 from "./images/life.jpg";
import personal2 from "./images/personal2-2.jpg";
import personal3 from "./images/home.jpg";
import business from "./images/smallBusinessBanner.jpg";
import largeBusiness from "./images/largeBusinessBanner.jpg";
import business1 from "./images/smallBusiness1.jpg";
import business2 from "./images/smallBusiness2.jpg";
import business3 from "./images/smallBusiness3.jpg";
import bigBusiness1 from "./images/bigBusiness1.jpg";
import bigBusiness2 from "./images/bigBusiness2.jpg";
import bigBusiness3 from "./images/bigBusiness3.jpg";
import commercial from "./images/commercialBoat.jpg";
import formBoatingResume from "./forms/AFN Boating Resume.pdf";
import formCharterApp from "./forms/AFN Charter App.pdf";
import formPleasureApp from "./forms/AFN Pleasure App 2023.pdf";
import rental from "./forms/Rental Insurance Application.pdf";
import homeowners from "./forms/Homeowners Insurance Application.pdf";
import auto from "./forms/Personal Auto Insurance Application.pdf";

function App() {
  return (
    <BrowserRouter>
      <ScrollToTop />
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="*" element={<Home />} />
        <Route
          path="/pleasure-yacht"
          element={
            <InsurancePage
              banner={boat}
              bannerTitle="PLEASURE YACHTS"
              type="Marine Insurance - Pleasure Yacht"
              title="GET THE BEST INSURANCE FOR YOUR YACHT"
              titleText="We insure any yacht, anywhere in the world. Blue water world cruisers to weekend hobbyist to racers and everything in between. From Mega Yachts to Jet Skies, we insure them all."
              pic1={sailboat}
              pic2={yacht}
              pic3={boatPic1}
              form1={formPleasureApp}
              form2={formBoatingResume}
              pageType="yacht"
            />
          }
        />
        <Route
          path="/commercial-marine"
          element={
            <InsurancePage
              banner={commercial}
              bannerTitle="COMMERCIAL MARINE"
              type="Marine Insurance - Commercial"
              title="GET THE BEST INSURANCE FOR YOUR COMMERCIAL VESSEL"
              titleText="We insure any commercial vessel, anywhere in the world. From Charter Yachts in Hawaii to tankers and freighters crossing oceans to Tour Boats to Tugs, we insure them all."
              pic1={freight}
              pic2={boatPic2}
              pic3={boatPic3}
              form1={formCharterApp}
              form2={formBoatingResume}
              pageType="commercial"
              opacity="opacity-img"
            />
          }
        />
        <Route
          path="/personal-insurance"
          element={
            <InsurancePage
              banner={personal}
              bannerTitle="PERSONAL INSURANCE"
              type="Personal Insurance"
              title="GET THE BEST PERSONAL INSURANCE"
              pic1={personal1}
              pic2={personal2}
              pic3={personal3}
              form3={rental}
              form4={homeowners}
              form5={auto}
              pageType="personal"
              opacity="opacity-75"
            />
          }
        />
        <Route
          path="/small-business"
          element={
            <InsurancePage
              banner={business}
              bannerTitle="SMALL BUSINESS INSURANCE"
              type="Small Business Insurance"
              title="GET THE BEST INSURANCE FOR YOUR SMALL BUSINESS"
              titleText="From Start Ups to mature businesses, we will custom design your business insurances, minimizing cost and educating you along the way with everything you will need to know. Mr. Bonner has is also a Business Consultant and provides many insights and suggestions to help his clients at no additional charge. From referrals to the best professionals in town to keep your business running smooth (CPA's and Attorney's to marketing services and web design) to improvement ideas on operations, etc. Mr. Bonner has years of business background to assist his clients when needed."
              pic1={business1}
              pic2={business2}
              pic3={business3}
              pageType="small-business"
              opacity="opacity-75"
            />
          }
        />
        <Route
          path="/large-business"
          element={
            <InsurancePage
              banner={largeBusiness}
              bannerTitle="LARGE BUSINESS INSURANCE"
              type="Large Business Insurance"
              title="GET THE BEST INSURANCE FOR YOUR LARGE BUSINESS"
              titleText="From public companies to privately held, 50 plus employees or more, we have the experience to custom design and advise the optimal coverages to keep your business running smooth."
              pic1={bigBusiness1}
              pic2={bigBusiness2}
              pic3={bigBusiness3}
              pageType="large-business"
              opacity="opacity-75"
            />
          }
        />
      </Routes>
      <Footer />
    </BrowserRouter>
  );
}

export default App;
