import React from "react";
import logo from "../images/logo-transparent-fullSize.png";

function GrowFade() {
  return (
    <div className="image-grow">
      <img src={logo} style={{ width: "300px" }} alt="logo" />
    </div>
  );
}

export default GrowFade;
