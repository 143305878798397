import Button from "react-bootstrap/Button";
import { MdOutlineMailOutline } from "react-icons/md";
import { BsTelephone } from "react-icons/bs";
import banner from "../images/contactBanner.jpg";
import logo from "../images/logo.jpg";

function InfoPages() {
  return (
    <div className="position-relative">
      <img
        src={banner}
        alt="banner"
        className="w-100 fullScreen"
        style={{ height: "50vh" }}
      />
      <div className="position-absolute top-50 start-50 translate-middle w-100 text-center">
        {/* <h1 className="text-white display-3 fw-bold shadows pb-4">
          ALLIED FINANCIAL NETWORK
        </h1> */}
        <img
          src={logo}
          alt="logo"
          style={{ width: "200px" }}
          className="mb-4 bg-white rounded px-2 py-1 shadow"
        />
        <div className="col-12">
          <Button
            variant="light"
            size="lg"
            href="tel:8088243626"
            className="shadow"
          >
            <BsTelephone className="mb-1" /> 808-824-3626
          </Button>
        </div>
        <div className="col-12">
          <Button
            variant="dark"
            href="mailto:steve@alliedfinancialnetwork.com"
            className="mt-4 shadow"
            size="lg"
          >
            <MdOutlineMailOutline className="mb-1" /> Email
          </Button>
        </div>
      </div>
    </div>
  );
}

export default InfoPages;
