import React from "react";
import { Link } from "react-router-dom";

function Card({ pic, title, link }) {
  return (
    <div className="position-relative">
      <Link to={link} className="card img-thumbnail rounded-1">
        <img src={pic} alt="card" className=" w-100" />
        <h2 className="position-absolute top-50 start-50-card translate-middle-card fw-bold shadows text-white w-100 text-center">
          {title}
        </h2>
      </Link>
    </div>
  );
}

export default Card;
