import React from "react";
import Card from "../components/Card";

function Section({ pics, title }) {
  return (
    <div className="container-lg">
      <h1 className="fw-bold text-center pb-2 mb-0">{title}</h1>
      <div className="row justify-content-center">
        {pics.map((item) => {
          return (
            <div key={item.pic} className="col-11 col-sm-6 col-md-4 mt-4 mb-0">
              <Card pic={item.pic} title={item.title} link={item.link} />
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default Section;
