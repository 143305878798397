import Button from "react-bootstrap/Button";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import { FiDownload } from "react-icons/fi";

function InsurancePage({
  banner,
  bannerTitle,
  title,
  titleText,
  pic1,
  pic2,
  pic3,
  type,
  form1,
  form2,
  form3,
  form4,
  form5,
  pageType,
  opacity,
}) {
  function capitalizeFirstLetter(str) {
    return str
      .toLowerCase()
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  }

  let slogan = "";
  if (pageType === "yacht" || pageType === "commercial") {
    slogan = "SPECIALIZING IN WORLDWIDE MARINE INSURANCE";
  }
  if (pageType === "small-business" || pageType === "large-business") {
    slogan = "INSURING ALL COMPANIES BIG AND SMALL";
  }
  if (pageType === "personal") {
    slogan = "INSURING ALL PERSONAL LINES";
  }

  return (
    <>
      <Helmet>
        <title>
          Allied Financial Network | {capitalizeFirstLetter(bannerTitle)}
        </title>
      </Helmet>
      <div className="position-relative bg-dark">
        <img
          src={banner}
          alt="banner"
          className={`w-100 fullScreen ${opacity}`}
          style={{ height: "75vh" }}
        />
        <div className="position-absolute top-50 start-50 translate-middle w-75 text-center">
          <h1 className="text-white display-3 fw-bold shadows">
            {bannerTitle}
          </h1>
          <h2 className="lh-base text-white mt-5 shadows">{slogan}</h2>

          <Button
            as={Link}
            to="/contact"
            state={{ insurance: type }}
            size="lg"
            variant="light"
            className="mt-4 shadow"
          >
            GET A QUOTE
          </Button>
          {pageType === "personal" && (
            <div className="mt-4 d-flex justify-content-center">
              <Button
                size="sm"
                className="fs-6 rounded me-3 border border-light-subtle"
                href={form3}
                download="Rental Insurance Application.pdf"
              >
                Rental Application <FiDownload />
              </Button>
              <Button
                size="sm"
                className="fs-6 rounded border border-light-subtle"
                href={form4}
                download="Homeowners Insurance Application.pdf"
              >
                Homeowners Application <FiDownload />
              </Button>
              <Button
                size="sm"
                className="fs-6 rounded ms-3 border border-light-subtle"
                href={form5}
                download="Personal Auto Insurance Application.pdf"
              >
                Auto Application <FiDownload />
              </Button>
            </div>
          )}
        </div>
      </div>
      <div className="container-lg py-5">
        <div className="row justify-content-center">
          <div className="col-11 col-md-10 col-lg-9">
            <h2>{title}</h2>
            <p>{titleText}</p>
            {pageType === "yacht" ? (
              <p className="mb-5">
                Download this form{" "}
                <Button
                  size="sm"
                  className="fs-6 rounded-pill"
                  href={form1}
                  download="AFN Pleasure App 2023.pdf"
                >
                  Pleasure App <FiDownload />
                </Button>{" "}
                and fill out the best you can and return with a copy of your
                latest survey if you have one and we can get started.
                <br />
                <br />
                Note, every yacht needs a captain so fill in this form{" "}
                <Button
                  size="sm"
                  className="fs-6 rounded-pill"
                  href={form2}
                  download="AFN Boating Resume.pdf"
                >
                  Boating Resume <FiDownload />
                </Button>{" "}
                to feature your marine background. Be as detailed as you can.
                With these two documents, you are on our way to superior
                coverage!
              </p>
            ) : (
              ""
            )}

            {pageType === "commercial" && (
              <p className="mb-5">
                We insure any commercial vessel, anywhere in the world. From
                Charter Yachts in Hawaii to tankers and freighters crossing
                oceans to Tour Boats to Tugs, we insure them all.
                <br />
                <br />
                Download this form{" "}
                <Button
                  size="sm"
                  className="fs-6 rounded-pill"
                  href={form1}
                  download="AFN Charter App.pdf"
                >
                  Charter App <FiDownload />
                </Button>{" "}
                and fill out the best you can, and return with a copy of your
                latest survey and resume form{" "}
                <Button
                  size="sm"
                  className="fs-6 rounded-pill"
                  href={form2}
                  download="AFN Boating Resume.pdf"
                >
                  Boating Resume <FiDownload />
                </Button>{" "}
                on your captain(s). Having a copy of their USCG license is
                helpful. With these two documents and a few attachments, you are
                on our way to superior coverage!
              </p>
            )}

            {pageType === "small-business" && (
              <>
                <p>Some of our favorite small businesses are:</p>
                <ul>
                  <li>Contractors</li>
                  <li>Restaurants</li>
                </ul>
                <p>
                  We provide all the necessary insurances for small businesses
                  including:
                </p>
                <ul>
                  <li>General Liability Insurance</li>
                  <li>Workman's Comp</li>
                  <li>Employee Benefits</li>
                  <li>Fleet Auto</li>
                  <li>Business Owners Policies</li>
                  <li>Professional Liability</li>
                  <li>Commercial Auto</li>
                  <li>Bond</li>
                  <li>Property</li>
                  <li>Business Interruption</li>
                </ul>
                <p className="mb-5">
                  WE DO EVERYTHING IN BUSINESS INSURANCE and will help you
                  custom design the perfect protection plan.
                </p>
              </>
            )}

            {pageType === "large-business" && (
              <>
                <p>Some of our favorite large companies are:</p>
                <ul>
                  <li>
                    Association of Apartment Owners (AOAO) & Home Owners
                    Association (HOA)
                  </li>
                  <li>Resorts</li>
                  <li>Hotels</li>
                  <li>Restaurants</li>
                  <li>Large Buildings</li>
                  <li>Marinas</li>
                  <li>Yacht Clubs</li>
                  <li>Large Fleet</li>
                  <li>Construction Companies</li>
                </ul>
                <p>
                  We provide all the necessary insurances for large companies
                  including:
                </p>
                <ul>
                  <li>General Liability Insurance</li>
                  <li>Workman's Comp</li>
                  <li>Employee Benefits</li>
                  <li>Fleet Auto</li>
                  <li>Business Owners Policies</li>
                  <li>Professional Liability</li>
                  <li>Commercial Auto</li>
                  <li>Bond</li>
                  <li>Property</li>
                  <li>Business Interruption</li>
                </ul>
                <p className="mb-5">
                  WE DO EVERYTHING IN BUSINESS INSURANCE and will help you
                  custom design the perfect protection plan.
                </p>
              </>
            )}

            {pageType === "personal" && (
              <>
                <ul>
                  <li>Auto / Home Insurance</li>
                  <li>Rentals</li>
                  <li>Marine / Boat / Yacht</li>
                  <li>
                    Life Insurance - Financial Planning Services, Consulting on
                    Trusts, etc. all included
                    <ul>
                      <li>Term - 10yr, 20yr, 30yr</li>
                      <li>Universal Life</li>
                      <li>Whole Life</li>
                      <li>Variable Life</li>
                      <li>Indexed Universal Life</li>
                      <li>Survivorship Life / 2nd to die Life</li>
                      <li>Premium Financing Custom Designed Plans</li>
                    </ul>
                  </li>
                  <li>Disability Insurance (protects your income)</li>
                  <li>Long Term Care Insurance</li>
                  <li>Medical / Dental / Vision Insurance</li>
                  <li>
                    Classic Car, RV, Motorcycle, 5th Wheel, Horse Trailers (We
                    love our Rodeo Families), High End Autos ($100k +)
                  </li>
                  <li>
                    Umbrella - all of the other underlying coverages need to be
                    written properly to qualify for an Umbrella policy so
                    knowing this ahead of time helps in the design for your
                    coverages.
                  </li>
                </ul>
                <p>
                  Flo is right about bundling and saving, putting all or a lot
                  of these coverages with the same carrier can reduce costs.
                </p>
                <p className="mb-5">
                  We will custom design a perfect insurance plan based on your
                  goals and insurance philosophies. We have 100's of carriers we
                  write business with, not captivated with one, allowing us to
                  delivery an optimal solution for your unique circumstances.
                  There is a lot that goes into doing this right. No claims
                  history clients have huge advantages with some carriers. Some
                  carriers are more lenient with a claim or incident. Only one
                  line of coverage need, certain carriers are great, others very
                  expensive. When you have tons of assets to handle, some
                  carriers will go out of their way to insure it all while
                  others simply cannot. Years of experience makes a big
                  difference when you want it done right.
                </p>
              </>
            )}
          </div>
        </div>
        <div className="row justify-content-center">
          <div className="col-11 col-sm-6 col-md-4 mb-4 mb-md-0">
            <img src={pic1} alt="pic1" className="w-100" />
            {/* <h3 className="pt-4">{pic1Title}</h3>
            <p className="mb-4 mb-sm-4 mb-md-0">{pic1Text}</p> */}
          </div>
          <div className="col-11 col-sm-6 col-md-4 mb-4 mb-md-0">
            <img src={pic2} alt="pic2" className="w-100" />
            {/* <h3 className="pt-4">{pic2Title}</h3>
            <p className="mb-4 mb-sm-4 mb-md-0">{pic2Text}</p> */}
          </div>
          <div className="col-11 col-sm-8 col-md-4">
            <img src={pic3} alt="pic3" className="w-100" />
            {/* <h3 className="pt-4">{pic3Title}</h3>
            <p className="mb-0">{pic3Text}</p> */}
          </div>
        </div>
        <div className="row justify-content-center">
          <div className="col-11 col-md-10 col-lg-9 mt-5">
            <h2 className="text-center">CONTACT US TODAY</h2>
            <p className="text-center mb-0">
              To learn more about acquiring the best insurance for your needs,
              fill out our{" "}
              <Link className="fs-5" state={{ insurance: type }} to="/contact">
                contact form
              </Link>
              , or call{" "}
              <a className="fs-5" href="tel:8088243626">
                808-824-3626
              </a>
              .
            </p>
          </div>
        </div>
      </div>
    </>
  );
}

export default InsurancePage;
